<template>
  <div class="about">
    <h1>暂无相关信息</h1>
  </div>
</template>

<script>
export default {
	name: "About"
}
</script>